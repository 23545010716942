import Emmiter from 'tiny-emitter'
import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin(() => {
  const emmiter = new Emmiter()

  return {
    provide: {
      eventHub: emmiter,
    },
  }
})
