import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin, useRouter, useRuntimeConfig } from '#imports'

async function lazyLoadSentryIntegrations () {
  // don't load on server
  if (! process.client) { return }

  Sentry.addIntegration(Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }))
}

function getSentryIntegrations () {
  // don't load on server
  if (! process.client) { return [] }
  const router = useRouter()

  return [Sentry.browserTracingIntegration({ router })]
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup (nuxtApp) {
    const vueApp = nuxtApp.vueApp

    const { public: { sentry } } = useRuntimeConfig()

    const instance = Sentry.init({
      app: vueApp,
      dsn: sentry.dsn,
      integrations: getSentryIntegrations(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.05,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://www.tripexpert.com'],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1,
    })

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations()

    return {
      provide: {
        sentry: instance,
      },
    }
  },
})
