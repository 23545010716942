import { defineNuxtPlugin } from 'nuxt/app'
import VueScrollTo from 'vue-scrollto'

export default defineNuxtPlugin(async (nuxtApp) => {
  nuxtApp.vueApp.directive('scroll-to', {
    mounted (el, binding) {
      el.addEventListener('click', (e: Event) => {
        e.preventDefault()
        VueScrollTo.scrollTo(binding.value, { offset: -70, easing: [0.12, 0, 0.39, 0] })
      })
    },
  })

  return {
    provide: {
      scrollTo: VueScrollTo.scrollTo,
    },
  }
})
