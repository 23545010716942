import { default as _91comparison_93wRbxXkPzUwMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/[comparison].vue?macro=true";
import { default as _91amenity_932m6GJY7WJzMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/[name]/[amenity].vue?macro=true";
import { default as comparisonssE5rWldBb9Meta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/[name]/comparisons.vue?macro=true";
import { default as indexeLPclSWBsrMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/[name]/index.vue?macro=true";
import { default as indexjaggUPegioMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/index.vue?macro=true";
import { default as aboutysgppH3vQGMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/about.vue?macro=true";
import { default as apipvMzDnbc8zMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/api.vue?macro=true";
import { default as business_45supportCHRVMGK7GJMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/business-support.vue?macro=true";
import { default as index4EIdCs5AnfMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/careers/index.vue?macro=true";
import { default as mobile_45software_45developerYg8vrkvEjdMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/careers/mobile-software-developer.vue?macro=true";
import { default as travel_45writerZ4SIDxgQtgMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/careers/travel-writer.vue?macro=true";
import { default as contact0qPGxqH8kwMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/contact.vue?macro=true";
import { default as experts_45choice_45awardsaiCxgfuCpSMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/experts-choice-awards.vue?macro=true";
import { default as explorejg4nE1M7aPMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/explore.vue?macro=true";
import { default as indexgOBl4XgSAGMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/index.vue?macro=true";
import { default as _91name_93V10fRZDvz2Meta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/lists/[name].vue?macro=true";
import { default as _91name_930rcE5WbzlIMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/posts/[name].vue?macro=true";
import { default as request_45hard_45copiesP4tbdPCzqrMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/request-hard-copies.vue?macro=true";
import { default as _91id_93klk9sVHsSwMeta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/saved/[id].vue?macro=true";
import { default as indexwshUWB68L8Meta } from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/saved/index.vue?macro=true";
export default [
  {
    name: "destination-type-comparison",
    path: "/:destination()/:type()/:comparison(.*-vs-.*)",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/[comparison].vue").then(m => m.default || m)
  },
  {
    name: "destination-type-name-amenity",
    path: "/:destination()/:type()/:name()/:amenity()",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/[name]/[amenity].vue").then(m => m.default || m)
  },
  {
    name: "destination-type-name-comparisons",
    path: "/:destination()/:type()/:name()/comparisons",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/[name]/comparisons.vue").then(m => m.default || m)
  },
  {
    name: "destination-type-name",
    path: "/:destination()/:type()/:name()",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: "destination-type",
    path: "/:destination()/:type()",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/[destination]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "business-support",
    path: "/business-support",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/business-support.vue").then(m => m.default || m)
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-mobile-software-developer",
    path: "/careers/mobile-software-developer",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/careers/mobile-software-developer.vue").then(m => m.default || m)
  },
  {
    name: "careers-travel-writer",
    path: "/careers/travel-writer",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/careers/travel-writer.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "experts-choice-awards",
    path: "/experts-choice-awards",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/experts-choice-awards.vue").then(m => m.default || m)
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lists-name",
    path: "/lists/:name()",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/lists/[name].vue").then(m => m.default || m)
  },
  {
    name: "posts-name",
    path: "/posts/:name()",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/posts/[name].vue").then(m => m.default || m)
  },
  {
    name: "request-hard-copies",
    path: "/request-hard-copies",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/request-hard-copies.vue").then(m => m.default || m)
  },
  {
    name: "saved-id",
    path: "/saved/:id()",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/saved/[id].vue").then(m => m.default || m)
  },
  {
    name: "saved",
    path: "/saved",
    component: () => import("/home/runner/work/tripexpert-frontend/tripexpert-frontend/pages/saved/index.vue").then(m => m.default || m)
  }
]