import pick from 'lodash/pick'
import { useRuntimeConfig } from 'nuxt/app'
import { useAuthStore } from '~/stores/auth'
import { useCookie } from '#imports'

let firebaseCache

export async function initFirebase () {
  if (firebaseCache) {
    return firebaseCache
  }
  useAuthStore().loading = true

  const [firebase, firestore, auth] = await Promise.all([import('firebase/app'), import('firebase/firestore'), import('firebase/auth')])

  const app = firebase.initializeApp(useRuntimeConfig().public.firebase)

  if (! firebase) {
    return Promise.reject(new Error('loading error'))
  }
  const db = firestore.getFirestore(app)

  firebaseCache = [firebase, db, auth]

  await new Promise(resolve => auth.getAuth(app).onAuthStateChanged((user) => {
    if (! user) {
      if (useAuthStore().user) {
        useAuthStore().user = null
      }
    } else {
      useAuthStore().user = pick(user, ['displayName', 'email', 'emailVerified', 'photoURL', 'uid', 'providerData'])
      useCookie('logged-in', { path: '/', maxAge: 60 * 60 * 24 }).value = '1'
    }
    resolve()
  }))

  useAuthStore().loading = false

  return [firebase, db, auth]
}
