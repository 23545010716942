import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'

export default defineNuxtRouteMiddleware(async (to) => {
  const redirects = [
    {
      from: '/experts-choice-award',
      to: '/experts-choice-awards',
    },
  ]

  const redirect = redirects.find(r => r.from === to)
  if (redirect) {
    navigateTo(redirect.to, { external: true, redirectCode: 301 })
  }
})
