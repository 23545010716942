import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import pluralize from 'pluralize'
import decode from '~/utils/decode-ent'
import { defineNuxtPlugin } from '#imports'

dayjs.extend(relativeTime)

function date (value, format = 'MM/DD/YYYY', invalid = 'Select Date') {
  const date = dayjs(value)
  if (! date.isValid()) { return invalid }

  return date.format(format)
}

function fromNow (value, invalid = 'Invalid Date') {
  const date = dayjs(value)
  if (! date.isValid()) { return invalid }

  return date.fromNow()
}

function currency (value, currency) {
  return decode(currency.symbol) + Math.round(value * currency.value_usd).toLocaleString('en-US')
}

function largeNum (num, decimals, maxunit) {
  if (num === 0) { return '0' }
  if (Number.isNaN(Number.parseFloat(num)) && ! Number.isFinite(num)) { return '' }
  const k = 1000
  const dm = (decimals !== null && ! Number.isNaN(decimals) && decimals >= 0) ? decimals : 2
  const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y', 'B']
  let i = Math.floor(Math.log(num) / Math.log(k))
  if (maxunit !== undefined) {
    const index = sizes.indexOf(maxunit)
    if (index !== -1) { i = index }
  }

  return `${Number.parseFloat((num / k ** i).toFixed(dm))}${sizes[i]}`
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      f: {
        date,
        fromNow,
        pluralize,
        currency,
        largeNum,
      },
    },
  }
})
