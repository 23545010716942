<template>
  <div class="flex min-h-screen w-screen flex-col items-center justify-center px-6 py-10">
    <div v-if="error.statusCode === 404">
      <h1 class="font-bw-gradual mb-6 text-center text-3xl font-bold leading-tight text-slate md:text-39px">
        Page not found.
      </h1>
      <p class="mb-8 max-w-890px text-center text-gray-dark md:text-xl">
        The URL you requested was not found. Sorry! If you think we should know about this, please
        <a href="/contact" class="text-blue-dark">let us know.</a>
        Otherwise, try one of these pages to continue on your journey:
      </p>
    </div>
    <div v-else>
      <h1 class="font-bw-gradual mb-6 text-center text-39px font-bold leading-tight text-slate">
        Something went wrong.
      </h1>
      <p class="mb-8 max-w-890px text-center text-xl text-gray-dark">
        Our server reported an error while processing your request. Sorry! If you think we should know about this,
        please <a href="/contact" class="text-blue-dark">let us know.</a> Otherwise, try one of these pages to continue on your journey:
      </p>
    </div>
    <div class="mb-6 flex flex-wrap justify-center space-x-6">
      <NuxtLink to="/" class="letter-sp15 mb-6 inline-block rounded-lg border border-gray-dark px-8 py-3 text-sm uppercase text-gray-dark hover:bg-gray-200">
        Home
      </NuxtLink>
      <NuxtLink to="/about" class="letter-sp15 mb-6 inline-block rounded-lg border border-gray-dark px-8 py-3 text-sm uppercase text-gray-dark hover:bg-gray-200">
        About
      </NuxtLink>
      <NuxtLink to="/explore" class="letter-sp15 mb-6 inline-block rounded-lg border border-gray-dark px-8 py-3 text-sm uppercase text-gray-dark hover:bg-gray-200">
        Explore
      </NuxtLink>
    </div>
    <img src="~/assets/images/page-error.svg" class="mx-auto max-w-full">
  </div>
</template>

<script>
import { useHead } from '#imports'
import { useError } from '#app'

export default {
  props: {
    error: { type: Object, default: null },
  },

  setup () {
    useHead({
      title: useError().statusCode === 404 ? 'Page not found - Tripexpert' : 'Something went wrong - Tripexpert',
    })
  },
}
</script>
