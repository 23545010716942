import { defineStore } from 'pinia'
import { initFirebase } from '~/utils/firebase'
import { useCookie } from '#imports'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    loading: false,
  }),

  actions: {
    async signOut () {
      const [, , auth] = await initFirebase()
      await auth.signOut(auth.getAuth())
      this.user = null
      useCookie('logged-in').value = null
    },
  },
})
