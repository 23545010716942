import revive_payload_client_4sVQNw7RlN from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import eventHub_YIW5hN5hb3 from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/eventHub.js";
import filters_znakZuvLqA from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/filters.js";
import fireauth_client_4evVjbnj1r from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/fireauth.client.js";
import gtag_client_R70iZRv6jK from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/gtag.client.js";
import observe_visibility_client_c3uYU2YAv4 from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/observe-visibility.client.js";
import pinia_log_iJk1efWLt2 from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/pinia-log.js";
import screen_AYzqh1bpFN from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/screen.js";
import scrollto_client_BPG2kQbA9g from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/scrollto.client.ts";
import sentry_3AyO8nEfhE from "/home/runner/work/tripexpert-frontend/tripexpert-frontend/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  plugin_AUP22rrBAc,
  eventHub_YIW5hN5hb3,
  filters_znakZuvLqA,
  fireauth_client_4evVjbnj1r,
  gtag_client_R70iZRv6jK,
  observe_visibility_client_c3uYU2YAv4,
  pinia_log_iJk1efWLt2,
  screen_AYzqh1bpFN,
  scrollto_client_BPG2kQbA9g,
  sentry_3AyO8nEfhE
]